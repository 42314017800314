@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Norlys Text';
        src: url('fonts/NORLYSTextUIBETAVF.woff') format('woff');
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'Norlys Headline';
        src: url('fonts/NORLYSHeadlineUIBETAVF.woff') format('woff');
        font-style: normal;
        font-weight: 700;
    }

    :focus {
        @apply outline-none ring-2 ring-green-strong;
    }
    :focus:not(:focus-visible) {
        @apply ring-transparent;
    }
    :focus-visible {
        @apply outline-none ring-2 ring-green-strong ring-offset-0;
    }
}

@layer components {
    .btn {
        @apply inline-block text-center min-w-[180px] font-bolder py-16 px-24 uppercase rounded-full bg-white tracking-wider shadow-md hover:shadow-md-hover hover:translate-y-[-1px];
        transition: transform 0.1s ease-in, box-shadow 0.25s ease-in;
    }

    .input-group input:focus,
    .input-group input:not(:placeholder-shown) {
        @apply pt-32 pb-16;
    }

    .input-group input:focus ~ .input-group__floating-label,
    .input-group input:not(:placeholder-shown) ~ .input-group__floating-label {
        @apply text-[12px] translate-y-[-12px] translate-x-[1px];
    }

    .input-group input:focus ~ .input-group__floating-label,
    .input-group input:not(:placeholder-shown) ~ .input-group__floating-label {
        @apply text-[12px] translate-y-[-12px] translate-x-[1px];
    }

    .ToggleButton {
        @apply relative;

        input {
            @apply absolute opacity-0;

            &:focus {
                & + label::before {
                    @apply border-green-strong;
                }

                &:checked + label::before {
                    @apply border-border-grey;
                }

                &::after {
                    @apply translate-x-24;
                }
            }

            &:checked {
                & + label {
                    @apply text-green-strong;

                    &::before {
                        @apply border-green-strong bg-green-strong;
                    }

                    &::after {
                        @apply translate-x-24;
                    }
                }
            }

            &:disabled {
                &,
                & + label {
                    @apply cursor-not-allowed pointer-events-none;
                }

                & + label {
                    &,
                    &::before,
                    &::after {
                        @apply opacity-50;
                    }
                }
            }
        }

        label {
            @apply inline-flex items-start leading-none transition-all ease-out duration-300;

            span {
                @apply text-grey-100;

                width: calc(100% - 56px);
            }

            &::before,
            &::after {
                @apply block content-[''] rounded-full transition-all ease-out;
            }

            &::before {
                @apply bg-brown-bright border-2 border-border-grey w-[56px] h-[30px] mr-16 duration-300;
            }

            &::after {
                @apply absolute left-[3px] top-[3px] bg-white shadow-sm w-24 h-24 duration-200;
            }
        }
    }

    .Select {
        @apply relative;

        select {
            @apply block w-full appearance-none bg-white ring-2 ring-border-grey rounded-md p-24 transition-all duration-300 ease-out bg-no-repeat;

            background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cdesc%3E Created with Sketch. %3C/desc%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23838383' stroke-width='1.25' d='M1 1l6 6 6-6'/%3E%3C/g%3E%3C/svg%3E");
            background-position: calc(100% - 16px) 50%;

            &::-ms-expand {
                @apply hidden;
            }

            &:focus {
                @apply ring-green-strong;
            }

            &:disabled {
                @apply opacity-50 pointer-events-none;
            }

            &.has-error {
                @apply ring-red-bright;
            }
        }
    }
}


/* DAWA */
.autocomplete-container {
    /* relative position for at de absolut positionerede forslag får korrekt placering.*/
    position: relative;
    width: 100%;
    max-width: 30em;
}

.autocomplete-container input {
    /* Både input og forslag får samme bredde som omkringliggende DIV */
    width: 100%;
    box-sizing: border-box;
}


.dawa-autocomplete-suggestions {

    @apply shadow-md rounded-lg bg-white mt-4;

    padding: 0;
    text-align: left;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9999;
    overflow-y: auto;
    box-sizing: border-box;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion {
    @apply text-grey-100 px-24 py-16 border-2 border-b-0 border-border-grey;

    margin: 0;
    list-style: none;
    cursor: pointer;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion.dawa-selected,
.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:hover {
    background: #f0f0f0;
}
